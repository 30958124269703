import { Language, CustomLocale } from '@/types/locales';

interface Locale {
  [key: string]: string | Locale;
}
type Messages = {
  [key in Language]: Locale
}

export default function replaceLocales(
  messages: Messages,
  customLocales: CustomLocale[] | undefined,
  language: Language) {
  if (!customLocales) {
    return messages;
  }

  const copyMessages = structuredClone(messages);
  const locales = copyMessages[language];
  customLocales.forEach(customLocale => {
    const keys = customLocale.key.split('.');

    keys.reduce((partialLocales: string | Locale, currentKey: string, index: number, array: string[]) => {
      if (typeof partialLocales !== 'object') {
        return {};
      }

      if (index + 1 === array.length && typeof partialLocales?.[currentKey] === 'string') {
        partialLocales[currentKey] = customLocale.value;
      }

      return partialLocales[currentKey];
    }, locales);
  });

  return copyMessages;
}
