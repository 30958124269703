<script setup lang="ts">
import { computed, inject, ref, watch, type Ref } from 'vue';
import { useQueryClient, useMutation } from 'vue-query';

import couponRedemptionApi from '@/api/coupon-redemption';
import type { Coupon } from '@/types/coupon';
import formatNumber from '@/utils/format-number';
import CuponStarImage from '@assets/images/cuponstar.png';

import CouponCard from './coupon-card.vue';

interface Props {
  open: boolean;
  coupon: Coupon;
  categoryName: string;
}

const props = defineProps<Props>();

interface Emits {
  (e: 'close'): void;
}

const emit = defineEmits<Emits>();

const userPoints = inject<Ref<number | undefined>>('points');

const balanceAfterRedemption = computed(() => (
  (userPoints?.value || 0) - props.coupon.pointCost
));

const isBalanceNegative = computed(() => (
  balanceAfterRedemption.value < 0
));

const couponRedemption = computed(() => props.coupon.lastRedemption);
const isRedeemed = computed(() => !!couponRedemption.value);
const stepIndexes = {
  view: 0,
  confirmPoints: 1,
  loading: 2,
  redeemSuccess: 3,
  redeemError: 4,
  couponInfo: 5,
};
const steps = ['view', 'confirmPoints', 'loading', 'redeemSuccess', 'redeemError', 'couponInfo'];
const currentStepIndex = ref(isRedeemed.value ? stepIndexes.couponInfo : stepIndexes.view);

const queryClient = useQueryClient();
const { mutate } = useMutation(
  () => couponRedemptionApi.create(props.coupon.id),
  {
    onSuccess: async () => {
      await queryClient.invalidateQueries('loyalty-get-points');
      await queryClient.invalidateQueries('cuponstar-categories');
      currentStepIndex.value = stepIndexes.redeemSuccess;
    },
    onError: () => {
      currentStepIndex.value = stepIndexes.redeemError;
    },
  },
);

const couponIsFree = computed(() => props.coupon.pointCost === 0);
function nextStep() {
  if (steps[currentStepIndex.value] === 'redeemSuccess') {
    currentStepIndex.value = stepIndexes.couponInfo;
  } else if (steps[currentStepIndex.value] === 'view' && couponIsFree.value) {
    currentStepIndex.value = stepIndexes.loading;
  } else {
    currentStepIndex.value += 1;
  }
}

watch(() => currentStepIndex.value, (newStepIndex) => {
  if (newStepIndex === stepIndexes.loading) {
    mutate();
  }
});

watch(() => props.coupon, () => {
  currentStepIndex.value = stepIndexes.view;
});
</script>
<template>
  <base-modal
    :open="open"
    position-option="mixed"
    custom-width="w-full md:w-3/5 md:w-fit md:min-w-96 md:max-w-4xl"
  >
    <button
      v-if="steps[currentStepIndex] !== 'redeemSuccess'"
      data-testid="close-redeem-modal"
      class="absolute right-4 top-7 z-10 md:top-4"
      @click="$emit('close')"
    >
      <base-svg
        name="close"
        class="size-4 fill-current"
      />
    </button>
    <coupon-card
      v-if="steps[currentStepIndex] === 'view'"
      class="mx-auto mt-2"
      :coupon="coupon"
      :category-name="categoryName"
      variant="redeem-modal"
      @call-to-action-clicked="() => nextStep()"
    />
    <div
      v-if="steps[currentStepIndex] === 'confirmPoints'"
      class="flex w-full flex-col items-center pt-6 text-center"
    >
      <div class="flex size-28 items-center justify-center rounded-full bg-secondary/10">
        <base-svg
          name="trophy-cup"
          class="w-14 fill-secondary"
        />
      </div>
      <div
        class="mt-4 flex flex-col gap-4"
      >
        <p class="font-bold text-gray-800">
          {{ $t('coupons.redeemPoints') }}
        </p>
        <p>
          {{ $t('coupons.redeemPointsFor') }}
          <span class="font-bold">
            {{ coupon.name }}
          </span>
        </p>
      </div>
      <div class="mt-6 flex w-full flex-col items-center text-right">
        <div class="flex flex-col gap-5">
          <p>
            {{ $t('coupons.pointCost') }}
            <span class="rounded bg-primary/10 px-2 py-1 text-primary">
              {{ formatNumber(coupon.pointCost) }}
            </span>
          </p>
          <p>
            {{ $t('coupons.balanceAfterRedemption') }}
            <span class="rounded bg-primary/10 px-2 py-1 text-primary">
              {{ formatNumber(balanceAfterRedemption) }}
            </span>
          </p>
        </div>
      </div>
      <base-button
        :disabled="isBalanceNegative"
        theme="primary-button"
        class="mt-14 w-full"
        @click="nextStep"
      >
        {{ isBalanceNegative ? $t('coupons.insufficientPoints') : $t('coupons.redeem') }}
      </base-button>
    </div>
    <div
      v-if="steps[currentStepIndex] === 'loading'"
      class="flex w-full flex-col items-center pt-6 text-center"
    >
      <div class="flex size-28 items-center justify-center rounded-full">
        <base-svg
          name="loading"
          class="h-14 animate-spin stroke-current"
        />
      </div>
      <div
        class="mt-4"
      >
        <p class="font-bold text-gray-800">
          {{ $t('coupons.loading') }}
        </p>
      </div>
    </div>
    <div
      v-if="steps[currentStepIndex] === 'redeemSuccess'"
      class="flex w-full flex-col items-center pt-6 text-center"
    >
      <div class="flex items-center justify-center">
        <base-svg
          name="check-circle"
          class="size-20 fill-success-600"
        />
      </div>
      <div
        class="mt-4 flex flex-col gap-4"
      >
        <p class="text-3xl font-bold text-gray-800">
          {{ $t('coupons.done') }}
        </p>
        <div class="flex flex-col gap-2">
          <p>
            {{ $t('coupons.theCoupon') }}
            <span class="rounded bg-secondary/10 px-2 py-1 text-primary">
              {{ coupon.name }}
            </span>
          </p>
          <p>
            {{ $t('coupons.hasBeenRedeemed') }}
          </p>
        </div>
      </div>
      <base-button
        theme="primary-button"
        class="mt-14 w-full"
        @click="nextStep"
      >
        {{ $t('coupons.continue') }}
      </base-button>
    </div>
    <div
      v-if="steps[currentStepIndex] === 'redeemError'"
      class="flex w-full flex-col items-center pt-6 text-center"
    >
      <div class="flex items-center justify-center">
        <base-svg
          name="exclamation-circle"
          class="size-20 fill-rose-700"
        />
      </div>
      <div
        class="mt-4 flex flex-col gap-4"
      >
        <p class="text-3xl font-bold text-gray-800">
          {{ $t('coupons.whoops') }}
        </p>
        <div class="flex flex-col gap-2">
          <p>
            {{ $t('coupons.couldNotBeRedeemed') }}
          </p>
        </div>
      </div>
      <base-button
        theme="primary-button"
        class="mt-14 w-full"
        @click="() => emit('close')"
      >
        {{ $t('common.accept') }}
      </base-button>
    </div>
    <div
      v-if="steps[currentStepIndex] === 'couponInfo'"
      class="flex w-full flex-col items-center pt-6 text-center"
    >
      <p class="mb-10 text-3xl font-bold">
        {{ $t('coupons.youAreSavingMoney') }}
      </p>
      <p class="mb-9 text-xl">
        {{ couponRedemption?.text }}
      </p>
      <div class="mb-16 bg-gray-50 px-7 py-4 text-2xl font-bold">
        {{ $t('coupons.code') }}: {{ couponRedemption?.code }}
      </div>
      <div class="flex items-center justify-center gap-1 text-lg text-gray-500">
        <p>
          {{ $t('coupons.providedBy') }}
        </p>
        <base-image
          class="inline-block"
          :src="CuponStarImage"
          :alt="$t('coupons.cuponstar')"
        />
      </div>
    </div>
  </base-modal>
</template>
