<script setup lang="ts">
import { PhPhone } from '@phosphor-icons/vue';
import { computed } from 'vue';

import type { FirstFooter } from '@/types/first-footer';
import type { LegalDocument } from '@/types/legal-document';

import LegalDocumentsLink from '../../legal-documents-links.vue';

interface Props {
  firstFooter: FirstFooter;
  legalDocuments: LegalDocument[];
  copyrightText: string;
  copyrightYear: string;
}

const colorStyles = {
  primary: {
    bg: 'bg-primary',
    text: 'text-primary',
  },
  secondary: {
    bg: 'bg-secondary',
    text: 'text-secondary',
  },
  black: {
    bg: 'bg-black',
    text: 'text-black',
  },
  white: {
    bg: 'bg-white',
    text: 'text-white',
  },
};
const props = withDefaults(
  defineProps<Props>(),
  {
    legalDocuments: () => [] as LegalDocument[],
    copyrightText: '',
    copyrightYear: '',
  },
);

const extendedBgColorVariant = computed(() => props.firstFooter.extendedBgColor || 'black');
const textColorVariant = computed(() => props.firstFooter.textColor || 'white');
const extendedBgColorClass = computed(() => colorStyles[extendedBgColorVariant.value].bg);
const textColorClass = computed(() => colorStyles[textColorVariant.value].text);

const logos = computed(() => {
  const validLogos = [
    { url: props.firstFooter.firstLogoUrl, webpUrl: props.firstFooter.firstLogoWebpUrl,
      blurhash: props.firstFooter.firstLogoBlurhash },
    { url: props.firstFooter.secondLogoUrl, webpUrl: props.firstFooter.secondLogoWebpUrl,
      blurhash: props.firstFooter.secondLogoBlurhash },
    { url: props.firstFooter.thirdLogoUrl, webpUrl: props.firstFooter.thirdLogoWebpUrl,
      blurhash: props.firstFooter.thirdLogoBlurhash },
    { url: props.firstFooter.fourthLogoUrl, webpUrl: props.firstFooter.fourthLogoWebpUrl,
      blurhash: props.firstFooter.fourthLogoBlurhash },
    { url: props.firstFooter.fifthLogoUrl, webpUrl: props.firstFooter.fifthLogoWebpUrl,
      blurhash: props.firstFooter.fifthLogoBlurhash },
  ].filter(logo => logo.url);

  return validLogos.map((logo, index) => ({
    ...logo,
    showSeparator: index < validLogos.length - 1,
  }));
});

const hasValidLogos = computed(() => logos.value.some(logo => logo.url));

</script>

<template>
  <div
    class="w-full"
    :class="[extendedBgColorClass, textColorClass]"
  >
    <div class="flex w-full flex-col justify-between gap-10 px-6 py-4 sm:flex-row sm:gap-0 sm:px-20 sm:py-8">
      <div class="flex flex-col gap-5">
        <div class="flex flex-row flex-wrap justify-center gap-8 sm:items-start sm:justify-start sm:gap-6 sm:gap-x-10">
          <template v-if="!hasValidLogos">
            <img
              class="h-4 w-full max-w-20 overflow-hidden rounded-lg object-contain sm:h-8"
              :alt="$t('common.logo')"
              :src="props.firstFooter.darkBgLogoUrl"
            >
          </template>
          <template
            v-for="(logo) in logos"
            v-else
            :key="logo.url"
          >
            <base-image
              v-if="logo.url"
              class="h-10 overflow-hidden rounded-lg object-contain sm:h-16"
              :src="logo.url"
              :webp-src="logo.webpUrl"
              :blurhash="logo.blurhash"
            />
            <div
              v-if="logo.showSeparator"
              class="hidden h-16 w-0.5 border-l border-gray-200 sm:block"
            />
          </template>
        </div>
        <div class="hidden flex-row sm:block">
          <legal-documents-link
            :legal-documents="legalDocuments"
            :copyright-text="copyrightText"
            :copyright-year="copyrightYear"
            :text-color-class="textColorClass"
            class="flex flex-row gap-x-10 text-xs"
            data-testid="legal-documents-link"
          />
        </div>
      </div>
      <div
        class="flex flex-row sm:mx-auto sm:mr-0 sm:w-max sm:flex-col sm:py-0"
      >
        <div class="block w-full sm:hidden">
          <legal-documents-link
            :legal-documents="legalDocuments"
            :copyright-text="copyrightText"
            :copyright-year="copyrightYear"
            :text-color-class="textColorClass"
            class="flex flex-col text-xs"
            data-testid="legal-documents-link"
          />
        </div>
        <div
          class="flex w-full justify-end self-end text-base sm:justify-start"
        >
          <div class="flex flex-col">
            <div
              v-if="firstFooter.contactPhone"
              class="flex flex-col py-0"
            >
              <p
                class="hidden flex-row py-1 text-justify text-xs sm:block"
              >
                {{ $t('sections.footers.firstFooter.contactPhoneCta') }}
              </p>
              <div class="flex flex-row justify-start sm:items-center">
                <PhPhone
                  weight="fill"
                  class="mr-1 flex size-4 first-letter:fill-current sm:mr-4 sm:size-8 sm:items-center"
                  :class="[textColorClass]"
                />
                <div class="flex flex-col items-center text-xs font-medium sm:font-bold">
                  <p>
                    {{ firstFooter.contactPhone }}
                  </p>
                  <p v-if="firstFooter.secondaryContactPhone">
                    {{ firstFooter.secondaryContactPhone }}
                  </p>
                </div>
              </div>
            </div>
            <base-image
              v-if="firstFooter.regulatoryComplianceImageUrl"
              class="h-5 max-w-sm justify-end self-end overflow-hidden object-contain md:h-9"
              :src="firstFooter.regulatoryComplianceImageUrl"
              :webp-src="firstFooter.regulatoryComplianceWebpImageUrl"
              :blurhash="firstFooter.regulatoryComplianceImageBlurhash"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
