<script setup lang="ts">
import { PhFileText } from '@phosphor-icons/vue';
import { ref } from 'vue';
import { useMq } from 'vue3-mq';

import { LegalDocument } from '@/types/legal-document';

import baseMokModalDocument from './base-mok-modal-document.vue';

interface Props {
  legalDocuments: LegalDocument[];
  copyrightText: string;
  copyrightYear: string;
  textColorClass: string;
}

defineProps<Props>();

const legalDocumentSelected = ref<LegalDocument>({
  name: '',
  contentBody: '',
});

const openModal = ref<boolean>(false);

const mq = useMq();

function handleClick(legalDocument: LegalDocument) {
  legalDocumentSelected.value = legalDocument;
  openModal.value = true;
}

function closeModal() {
  openModal.value = false;
}
</script>

<template>
  <div v-if="mq.xs">
    <base-mok-modal-document
      :is-open="openModal"
      :title="legalDocumentSelected?.name"
      :content="legalDocumentSelected?.contentBody"
      :icon="PhFileText"
      :hide-arrow="true"
      @cancel="closeModal"
      @close="closeModal"
      @confirm="closeModal"
    />
    <a
      v-for="(legalDocument) in legalDocuments"
      :key="legalDocument.name"
      class="cursor-pointer underline"
      :class="[textColorClass, 'opacity-30']"
      @click.prevent="() => handleClick(legalDocument)"
    >
      {{ legalDocument.name }}
    </a>
    <p
      v-if="copyrightText && copyrightYear"
      :class="[textColorClass, 'opacity-30']"
    >
      Copyright © {{ copyrightYear }}
    </p>
  </div>
  <div v-else>
    <base-mok-modal-document
      :is-open="openModal"
      :title="legalDocumentSelected?.name"
      :content="legalDocumentSelected?.contentBody"
      :icon="PhFileText"
      :hide-arrow="true"
      @cancel="closeModal"
      @close="closeModal"
      @confirm="closeModal"
    />
    <p
      v-if="copyrightText && copyrightYear"
      :class="[textColorClass, 'opacity-30']"
    >
      {{ copyrightText }} Copyright © {{ copyrightYear }}
    </p>
    <a
      v-for="(legalDocument) in legalDocuments"
      :key="legalDocument.name"
      class="cursor-pointer underline"
      :class="[textColorClass, 'opacity-30']"
      @click.prevent="() => handleClick(legalDocument)"
    >
      {{ legalDocument.name }}
    </a>
  </div>
</template>
