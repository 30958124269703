import { CustomLocale, Language } from '@/types/locales';

type SiteVariables = {
  locale: Language;
  hasLoyalty: boolean;
  isLoginRequired: boolean;
  isRedeMaisSaude: boolean;
  featureFlags: {
    serviceHistory: boolean;
  },
  customLocales: CustomLocale[];
}

export interface WebappWindow extends Window {
  siteVariables: SiteVariables;
}

export default function useSiteVariables() {
  return (window as WebappWindow & typeof globalThis).siteVariables;
}
