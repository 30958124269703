<script setup lang="ts">
import { MokButton } from '@mok-labs/components';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import groupBy from 'lodash/groupBy';
import type { FormContext } from 'vee-validate';
import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation, useQueryClient } from 'vue-query';
import * as yup from 'yup';

import serviceApi, { type Values } from '@/api/service';
import serviceRedemptionApi from '@/api/service-redemption';
import useSiteVariables from '@/composables/useSiteVariables';
import useStepper from '@/composables/useStepper';
import type { Input } from '@/types/input';
import type { NestedInput } from '@/types/nested-input';
import type { Service } from '@/types/service';
import type { WebApp } from '@/types/web-app';

import BaseMokStepper from './base-mok-stepper.vue';
import LoyaltyRedemptionErrorModal from './loyalty-redemption-error-modal.vue';
import ServiceFormCard from './service-form-card.vue';
import ServiceFormErrorModal from './service-form-error-modal.vue';
import ServiceFormSuccessPage from './service-form-success-page.vue';

export type Props = {
  webApp: WebApp;
  service: Service;
  backUrl: string;
  countryFlagIcon?: string;
  phonePrefix: string;
  mobilePhonePattern: string
};

const props = defineProps<Props>();
const { hasLoyalty } = useSiteVariables();
const queryClient = useQueryClient();
const loyaltyQueryKey = 'loyalty-get-points';

const { t } = useI18n({});

const groupedInputs = groupBy(props.service.inputs, 'kind');

const showSuccessPage = ref(false);
const showRedemptionError = ref(false);
const form = ref<FormContext | null>(null);
const formValues = ref<Values>({});
const steps = Object.keys(groupedInputs);
const translatedFormValues = ref<Values>({});
const formError = ref<boolean>(false);

const {
  currentStepIndex,
  nextStep,
  isLastStep,
  previousStep,
} = useStepper(steps);

function createInputSchema(input: Input) {
  const label = input.customName || t(`serviceForm.${input.kind}.inputs.${input.name}`) || input.name;
  const baseSchema = yup.string().required().label(label);
  const schemaByType: Record<string, yup.AnySchema> = {
    'google_autocomplete': yup.object().required().label(label),
    'mobile_phone': baseSchema.matches(
      new RegExp(`${props.mobilePhonePattern}$`),
      t('serviceForm.personal.inputs.mobilePhoneNumberValidation'),
    ),
  };
  const schema = schemaByType[input.inputType] || baseSchema;

  return {
    [`${input.name}-${input.kind}`]: schema,
  };
}

function createNestedInputSchema(input: NestedInput) {
  const labelFirst = t(`serviceForm.${input.kind}.inputs.${input.firstName}`) || input.firstName;
  const labelSecond = t(`serviceForm.${input.kind}.inputs.${input.secondName}`) || input.secondName;

  return {
    [`${input.firstName}-${input.kind}`]: yup.string().required().label(labelFirst),
    [`${input.secondName}-${input.kind}`]: yup.string().required().label(labelSecond),
  };
}

const validationSchemas = computed(() => Object.keys(groupedInputs).map((group) => {
  const schema = {};

  groupedInputs[group].forEach((input) => {
    if ('apiName' in input && !input.optional) {
      Object.assign(schema, createInputSchema(input));
    } else if ('firstApiName' in input) {
      Object.assign(schema, createNestedInputSchema(input));
    }
  });

  return yup.object().shape(schema);
}));

const {
  mutate: updateServiceRedemptionMutate,
  isLoading: updateServiceRedemptionIsLoading,
} = useMutation(
  () => serviceRedemptionApi.update(props.service.lastRedemption?.id as number, new Date().toISOString()),
  {
    onSuccess: async () => {
      await queryClient.invalidateQueries(loyaltyQueryKey);
    },
    onError: () => {
      showRedemptionError.value = true;
    },
  },
);

const hasLoyaltyCostAndRedemption = computed(() => (
  hasLoyalty && props.service.pointCost > 0 && props.service.lastRedemption
));

const {
  mutate: assistancesMutate,
  isLoading: assistancesIsLoading,
} = useMutation(
  (values: Values) =>
    serviceApi.assistances(props.service.id, props.service.contractId, values),
  {
    onSuccess: () => {
      if (hasLoyaltyCostAndRedemption.value) {
        updateServiceRedemptionMutate();
      }
      showSuccessPage.value = true;
      formError.value = false;
    },
    onError: () => {
      showSuccessPage.value = false;
      formError.value = true;
    },
  },
);

function translateFormData(values: Values) {
  const translatedValues: Values = {};
  Object.keys(values).forEach((key) => {
    const lookupName = key.split('-')[0];
    const input = props?.service?.inputs?.find((i) =>
      [i.name, i.firstName, i.secondName].includes(lookupName),
    );
    const { apiName, firstName, firstApiName, secondApiName, kind } = input;

    if (apiName) {
      translatedValues[`${apiName}-${kind}`] = values[key];
    } else {
      const apiNameKey = firstName === key ? firstApiName : secondApiName;
      translatedValues[`${apiNameKey}-${kind}`] = values[key];
    }
  });
  translatedValues['comuna_origen-timing'] = values['address-timing']?.locality || '';
  translatedFormValues.value = translatedValues;
}

function addPhonePrefix(values: Values) {
  props.service.inputs?.forEach((input) => {
    if (input.inputType === 'mobile_phone' && 'name' in input) {
      const inputKey = `${input.name}-${input.kind}`;
      values[inputKey] = `${props.phonePrefix}${values[inputKey]}`;
    }
  });
}

function askAssistance(values: Values) {
  addPhonePrefix(values);
  translateFormData(values);
  assistancesMutate(translatedFormValues.value);
}

function validateForm(values: Values) {
  formValues.value = { ...formValues.value, ...values };
  if (isLastStep.value) {
    askAssistance(formValues.value);
  } else {
    nextStep();
  }
}

function toggleRedemptionError() {
  showRedemptionError.value = !showRedemptionError.value;
}

const isLoading = computed(() => (
  assistancesIsLoading.value || updateServiceRedemptionIsLoading.value
));

onMounted(() => {
  const referrerUrl = sessionStorage.getItem('referrerUrl') || props.backUrl;
  const familyIndex = sessionStorage.getItem('selectedFamilyIndex');
  const url = familyIndex ? `${referrerUrl}?family_index=${familyIndex}` : referrerUrl;
  sessionStorage.setItem('backUrl', url);
  sessionStorage.removeItem('referrerUrl');
  sessionStorage.removeItem('selectedFamilyIndex');
});

function getBackUrl(): void {
  const storedUrl = sessionStorage.getItem('backUrl');
  window.location.href = storedUrl || props.backUrl;
}

function closeErrorModal(): void {
  formError.value = false;
}

function onBackClick() {
  if (currentStepIndex.value > 0) {
    previousStep();
  } else {
    getBackUrl();
  }
}

const breakpoints = useBreakpoints(breakpointsTailwind);
const isMd = breakpoints.smallerOrEqual('md');
const buttonSize = computed(() => (isMd.value ? 'medium' : 'large'));

</script>

<template>
  <div class="flex grow flex-col bg-gray-50">
    <loyalty-redemption-error-modal
      :open="showRedemptionError"
      @close="toggleRedemptionError"
    />
    <service-form-error-modal
      :active="formError"
      @close="closeErrorModal"
    />
    <service-form-success-page
      v-if="showSuccessPage"
      :service="service"
      :form-values="formValues"
    />
    <div
      v-else
      class="flex max-w-2xl flex-col gap-y-4 self-center px-6 py-8 md:gap-y-9 md:px-0 md:py-14"
    >
      <div class="flex flex-col gap-y-2">
        <i18n-t
          keypath="serviceForm.title"
          tag="h1"
          class="text-center text-2xl font-medium md:text-3xl"
        >
          <template #service>
            <span class="text-primary-700">
              {{ service.name }}
            </span>
          </template>
        </i18n-t>
        <p class="pt-2 text-center text-base text-gray-500 md:text-xl">
          {{ $t('serviceForm.steps', {steps: steps.length}) }}
        </p>
      </div>
      <service-form-card
        :service="service"
      />
      <div
        v-for="(inputGroup, group, index) in groupedInputs"
        :key="group"
      >
        <v-form
          v-if="currentStepIndex === index"
          ref="form"
          :validation-schema="validationSchemas[currentStepIndex]"
          class="flex w-full flex-col items-center "
          :data-testid="'service-form-' + index"
          @submit="validateForm"
        >
          <div class="w-full rounded-2xl border border-gray-200 bg-white">
            <base-mok-stepper
              :steps="steps"
              :current-step="currentStepIndex"
              class="border-b px-8 py-6 md:px-16 md:py-8"
            />
            <h2 class="px-8 py-4 text-black md:pb-5 md:pt-6 ">
              {{ $t(`serviceForm.${group}.title`) }}
            </h2>
            <div class="grid grid-cols-1 gap-8 px-8 pb-6 md:grid-cols-2 md:pb-8">
              <template
                v-for="input in inputGroup"
                :key="`service-input-${input.id}`"
              >
                <service-form-nested-input
                  v-if="input.inputType.includes('nested')"
                  :input="input"
                  :translation-path="`serviceForm.${group}.inputs`"
                />
                <service-form-input
                  v-else
                  :input="input"
                  :translation-path="`serviceForm.${group}.inputs`"
                  :country-flag-icon="countryFlagIcon"
                  :phone-prefix="phonePrefix"
                />
              </template>
            </div>
          </div>
          <div class="my-8 flex w-full flex-col-reverse items-center justify-end gap-x-6 gap-y-4 md:flex-row">
            <mok-button
              data-testid="back-button"
              type="button"
              variant="link"
              :label="$t('common.back')"
              :full-width="isMd"
              :size="buttonSize"
              @click="onBackClick"
            />
            <mok-button
              type="submit"
              variant="primary"
              :data-testid="'continue-button'"
              :label="$t('serviceForm.continue')"
              :loading="isLoading"
              :disabled="isLoading"
              :size="buttonSize"
              :full-width="isMd"
            />
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>
