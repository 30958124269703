import Notifications from '@kyvg/vue3-notification';
import { camelizeKeys } from 'humps';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { VueQueryPlugin } from 'vue-query';
import { Vue3Mq } from 'vue3-mq';
import { setLocale } from 'yup';
import * as yupLocales from 'yup-locales';
import '@rails/actiontext';

import '../css/application.css';

import ApplicationLayout from '../components/application-layout.vue';
import BaseBadge from '../components/base-badge.vue';
import BaseBreadcrumb from '../components/base-breadcrumb.vue';
import BaseButton from '../components/base-button.vue';
import BaseCard from '../components/base-card.vue';
import BaseCheckboxInput from '../components/base-checkbox-input.vue';
import BaseDateInput from '../components/base-date-input.vue';
import BaseError from '../components/base-error.vue';
import BaseGoogleAutocompleteInput from '../components/base-google-autocomplete-input.vue';
import BaseIconButton from '../components/base-icon-button.vue';
import BaseImage from '../components/base-image.vue';
import BaseInput from '../components/base-input.vue';
import BaseLink from '../components/base-link.vue';
import BaseMediaPlayer from '../components/base-media-player.vue';
import BaseModal from '../components/base-modal.vue';
import BaseMokEmptyState from '../components/base-mok-empty-state.vue';
import BaseMokIcon from '../components/base-mok-icon.vue';
import BaseMokStepper from '../components/base-mok-stepper.vue';
import BaseMokTag from '../components/base-mok-tag.vue';
import BaseNestedSelect from '../components/base-nested-select.vue';
import BasePhoneInput from '../components/base-phone-input.vue';
import BaseRichText from '../components/base-rich-text.vue';
import BaseSelect from '../components/base-select.vue';
import BaseServiceSelect from '../components/base-service-select.vue';
import BaseSvg from '../components/base-svg.vue';
import BaseSwipeableCards from '../components/base-swipeable-cards.vue';
import BaseTimeInput from '../components/base-time-input.vue';
import BaseTooltip from '../components/base-tooltip.vue';
import CouponsView from '../components/coupons-view.vue';
import FamilyView from '../components/family-view.vue';
import IframeCallback from '../components/iframe-callback.vue';
import IframeServiceView from '../components/iframe-service-view.vue';
import LoyaltyRedeemableContent from '../components/loyalty-redeemable-content.vue';
import LoyaltyRewardableContent from '../components/loyalty-rewardable-content.vue';
import FirstFooter from '../components/sections/footers/first-footer.vue';
import FourthFooter from '../components/sections/footers/fourth-footer.vue';
import SecondFooter from '../components/sections/footers/second-footer.vue';
import ThirdFooter from '../components/sections/footers/third-footer.vue';
import FirstHelpCenter from '../components/sections/help-centers/first-help-center.vue';
import EighthHero from '../components/sections/heroes/eighth-hero.vue';
import FifthHero from '../components/sections/heroes/fifth-hero.vue';
import FirstHero from '../components/sections/heroes/first-hero.vue';
import FourthHero from '../components/sections/heroes/fourth-hero.vue';
import SecondHero from '../components/sections/heroes/second-hero.vue';
import SeventhHero from '../components/sections/heroes/seventh-hero.vue';
import SixthHero from '../components/sections/heroes/sixth-hero.vue';
import ThirdHero from '../components/sections/heroes/third-hero.vue';
import EighthMainContent from '../components/sections/main-contents/eighth-main-content.vue';
import FifthMainContent from '../components/sections/main-contents/fifth-main-content.vue';
import FirstMainContent from '../components/sections/main-contents/first-main-content.vue';
import FourthMainContent from '../components/sections/main-contents/fourth-main-content.vue';
import SecondMainContent from '../components/sections/main-contents/second-main-content.vue';
import SeventhMainContent from '../components/sections/main-contents/seventh-main-content.vue';
import SixthMainContent from '../components/sections/main-contents/sixth-main-content.vue';
import ThirdMainContent from '../components/sections/main-contents/third-main-content.vue';
import FirstNavbar from '../components/sections/navbars/first-navbar.vue';
import FourthNavbar from '../components/sections/navbars/fourth-navbar.vue';
import SecondNavbar from '../components/sections/navbars/second-navbar.vue';
import ThirdNavbar from '../components/sections/navbars/third-navbar.vue';
import ServiceFormInput from '../components/service-form-input.vue';
import ServiceFormNestedInput from '../components/service-form-nested-input.vue';
import ServiceForm from '../components/service-form.vue';
import ServiceMediaItemShow from '../components/service-media-item-show.vue';
import ServiceRedemptionsList from '../components/service-redemptions-list.vue';
import UserEditForm from '../components/user-edit-form.vue';
import UserServicesHistoryIndexView from '../components/user-services-history-index-view.vue';
import UserServicesHistoryShowView from '../components/user-services-history-show-view.vue';
import useSiteVariables from '../composables/useSiteVariables';
import Locales from '../locales/locales';

const { locale: siteLocale } = useSiteVariables();

const locale = siteLocale || 'es-CL';
const localeWithoutCountryCode = locale.split('-')[0];
setLocale(yupLocales[localeWithoutCountryCode as keyof typeof yupLocales]);

type LocalesSchema = typeof Locales.messages['es-CL'];

const i18n = createI18n<[LocalesSchema], 'es-CL' >({
  legacy: false,
  locale,
  messages: Locales.messages,
  datetimeFormats: Locales.datetimeFormats,
});

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({
    components: {
      ApplicationLayout,
      CouponsView,
      FamilyView,
      ServiceForm,
      ServiceRedemptionsList,
      IframeCallback,
      IframeServiceView,
      LoyaltyRedeemableContent,
      LoyaltyRewardableContent,
      FirstNavbar,
      SecondNavbar,
      ThirdNavbar,
      FourthNavbar,
      FirstHero,
      FifthHero,
      SecondHero,
      ThirdHero,
      FourthHero,
      SixthHero,
      SeventhHero,
      EighthHero,
      FirstMainContent,
      SecondMainContent,
      ThirdMainContent,
      FourthMainContent,
      FifthMainContent,
      SixthMainContent,
      SeventhMainContent,
      EighthMainContent,
      FirstFooter,
      SecondFooter,
      ThirdFooter,
      FourthFooter,
      FirstHelpCenter,
      UserEditForm,
      ServiceMediaItemShow,
      UserServicesHistoryIndexView,
      UserServicesHistoryShowView,
    },
    methods: {
      camelizeKeys,
    },
  });

  const components = [
    { name: 'BaseBadge', component: BaseBadge },
    { name: 'BaseButton', component: BaseButton },
    { name: 'BaseCard', component: BaseCard },
    { name: 'BaseCheckboxInput', component: BaseCheckboxInput },
    { name: 'BaseDateInput', component: BaseDateInput },
    { name: 'BaseTimeInput', component: BaseTimeInput },
    { name: 'BaseMokEmptyState', component: BaseMokEmptyState },
    { name: 'BaseError', component: BaseError },
    { name: 'BaseGoogleAutocompleteInput', component: BaseGoogleAutocompleteInput },
    { name: 'BaseIconButton', component: BaseIconButton },
    { name: 'BaseImage', component: BaseImage },
    { name: 'BaseMokIcon', component: BaseMokIcon },
    { name: 'BaseInput', component: BaseInput },
    { name: 'BaseLink', component: BaseLink },
    { name: 'BaseMediaPlayer', component: BaseMediaPlayer },
    { name: 'BaseModal', component: BaseModal },
    { name: 'BaseNestedSelect', component: BaseNestedSelect },
    { name: 'BasePhoneInput', component: BasePhoneInput },
    { name: 'BaseRichText', component: BaseRichText },
    { name: 'BaseSelect', component: BaseSelect },
    { name: 'BaseServiceSelect', component: BaseServiceSelect },
    { name: 'BaseMokStepper', component: BaseMokStepper },
    { name: 'BaseSwipeableCards', component: BaseSwipeableCards },
    { name: 'BaseMokTag', component: BaseMokTag },
    { name: 'BaseBreadcrumb', component: BaseBreadcrumb },
    { name: 'ServiceFormInput', component: ServiceFormInput },
    { name: 'ServiceFormNestedInput', component: ServiceFormNestedInput },
    { name: 'BaseTooltip', component: BaseTooltip },
    { name: 'BaseSvg', component: BaseSvg },
    { name: 'VField', component: Field },
    { name: 'VForm', component: Form },
    { name: 'VErrorMessage', component: ErrorMessage },
  ];

  components.forEach(({ name, component }) => {
    app.component(name, component);
  });

  app.use(i18n);
  app.use(Vue3Mq, { preset: 'tailwind' });
  app.use(VueQueryPlugin);
  app.use(Notifications);
  app.mount('#vue-app');

  return app;
});
