<script setup lang="ts">
import { MokButton } from '@mok-labs/components';
import { PhX, PhArrowLeft } from '@phosphor-icons/vue';
import DOMPurify from 'dompurify';
import { computed, ref, type Component } from 'vue';
import { useI18n } from 'vue-i18n';

import BaseMokIcon from '@/components/base-mok-icon.vue';

import BaseMokModal from './base-mok-modal.vue';

export type Props = {
  isOpen: boolean;
  title: string;
  content: string;
  icon?: Component;
  hideArrow?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  hideArrow: false,
});

const emits = defineEmits<{(e: 'close'): void, (e: 'confirm'): void, (e: 'cancel'): void }>();
const { t } = useI18n();
const isBottom = ref(false);
const sanitizedContent = computed(() => DOMPurify.sanitize(props.content));

function accept() {
  emits('confirm');
}

function cancel() {
  emits('cancel');
}

function closeModal() {
  emits('close');
}

function isScrollableElement(element: EventTarget | null): element is HTMLElement {
  return element instanceof HTMLElement;
}

function onScroll(event: Event) {
  const target = event.target;
  if (!isScrollableElement(target)) return;

  const scrollMargin = 112;
  if (target.scrollTop + target.clientHeight + scrollMargin >= target.scrollHeight) {
    isBottom.value = true;

    return;
  }
  isBottom.value = false;
}

</script>

<template>
  <base-mok-modal
    :is-open="isOpen"
    mobile-position="bottom"
    blur-overlay="2xl"
    class="h-5/6 w-screen max-w-none border-2 border-gray-200 md:h-4/6 md:w-7/12 md:max-w-none md:border-0"
  >
    <header
      class="flex flex-row items-center gap-4 px-6 pb-4 pt-6"
    >
      <PhArrowLeft
        v-if="!hideArrow"
        :size="24"
      />
      <base-mok-icon
        v-if="icon"
        color="primary"
        :icon="icon"
        size="lg"
      />
      <h3 class="text-xl font-semibold">
        {{ title }}
      </h3>
      <button
        class="ml-auto"
        @click="closeModal"
      >
        <PhX
          :size="24"
        />
      </button>
    </header>
    <div
      class="relative flex-1 overflow-y-scroll border-y-2 border-gray-200 px-6 pb-2 pt-6"
      @scroll="onScroll"
    >
      <base-rich-text
        data-testid="sanitized-content"
        :text="sanitizedContent"
      />
      <div
        v-if="!isBottom"
        data-testid="fade"
        class="sticky bottom-[-8px] h-24 w-full bg-gradient-to-b from-white/40 to-white md:bottom-[-10px] md:h-28"
      />
    </div>
    <div class="flex flex-row justify-around gap-4 p-3 md:items-center md:justify-end md:p-6">
      <mok-button
        :label="t('common.cancel')"
        class="w-full md:w-auto"
        variant="secondary"
        @click="cancel"
      />
      <mok-button
        :label="t('common.accept')"
        class="w-full md:w-auto"
        @click="accept"
      />
    </div>
  </base-mok-modal>
</template>
