<script setup lang="ts">
import { MokButton } from '@mok-labs/components';
import { PhPhone, PhHouse, PhEnvelope, PhInstagramLogo } from '@phosphor-icons/vue';
import { computed } from 'vue';

import { Color } from '@/types/color';
import type { LegalDocument } from '@/types/legal-document';
import type { SecondFooter } from '@/types/second-footer';
import FacebookIcon from '@assets/images/facebook.svg';

import LegalDocumentsLink from '../../legal-documents-links.vue';

interface Props {
  secondFooter: SecondFooter;
  legalDocuments: LegalDocument[];
  copyrightText: string;
  copyrightYear: string;
}

const colorStyles = {
  primary: {
    bg: 'bg-primary',
    text: 'text-primary',
    iconVariant: 'primary',
  },
  secondary: {
    bg: 'bg-secondary',
    text: 'text-secondary',
    iconVariant: 'secondary',
  },
  black: {
    bg: 'bg-black',
    text: 'text-black',
    iconVariant: 'gray',
  },
  white: {
    bg: 'bg-white',
    text: 'text-white',
    iconVariant: 'white',
  },
};

const props = withDefaults(
  defineProps<Props>(),
  {
    legalDocuments: () => [] as LegalDocument[],
    copyrightText: '',
    copyrightYear: '',
  },
);

const extendedBgColorVariant = computed(() => props.secondFooter.extendedBgColor || 'white');
const textColorVariant = computed(() => props.secondFooter.textColor || 'black');
const iconColorVariant = computed(() => props.secondFooter.iconColor || 'primary');
const extendedBgColorClass = computed(() => colorStyles[extendedBgColorVariant.value].bg);
const textColorClass = computed(() => colorStyles[textColorVariant.value].text);
const iconColorClass = computed(() =>
colorStyles[iconColorVariant.value].iconVariant as Color);

const logos = computed(() => {
  const validLogos = [
    { url: props.secondFooter.firstLogoUrl, webpUrl: props.secondFooter.firstLogoWebpUrl,
      blurhash: props.secondFooter.firstLogoBlurhash },
    { url: props.secondFooter.secondLogoUrl, webpUrl: props.secondFooter.secondLogoWebpUrl,
      blurhash: props.secondFooter.secondLogoBlurhash },
    { url: props.secondFooter.thirdLogoUrl, webpUrl: props.secondFooter.thirdLogoWebpUrl,
      blurhash: props.secondFooter.thirdLogoBlurhash },
    { url: props.secondFooter.fourthLogoUrl, webpUrl: props.secondFooter.fourthLogoWebpUrl,
      blurhash: props.secondFooter.fourthLogoBlurhash },
    { url: props.secondFooter.fifthLogoUrl, webpUrl: props.secondFooter.fifthLogoWebpUrl,
      blurhash: props.secondFooter.fifthLogoBlurhash },
  ].filter(logo => logo.url);

  return validLogos.map((logo, index) => ({
    ...logo,
    showSeparator: index < validLogos.length - 1,
  }));
});

const hasValidLogos = computed(() => logos.value.some(logo => logo.url));

</script>

<template>
  <div
    class="w-full"
    :class="[extendedBgColorClass, textColorClass]"
  >
    <div class="flex w-full flex-col gap-7 px-6 py-4 sm:flex-row sm:justify-between sm:gap-0 sm:px-20 sm:py-8">
      <div class="flex flex-row justify-between sm:flex-col sm:gap-0">
        <div class="flex flex-col justify-center gap-4 sm:mb-6 sm:flex-row sm:flex-wrap sm:items-start sm:justify-start sm:gap-6 sm:gap-x-10">
          <template v-if="!hasValidLogos">
            <img
              class="h-4 w-full max-w-20 overflow-hidden object-contain sm:h-8"
              :alt="$t('common.logo')"
              :src="props.secondFooter.logoUrl"
            >
          </template>
          <template
            v-for="(logo) in logos"
            v-else
            :key="logo.url"
          >
            <base-image
              v-if="logo.url"
              class="h-10 max-w-fit overflow-hidden object-contain sm:h-16"
              :src="logo.url"
              :webp-src="logo.webpUrl"
              :blurhash="logo.blurhash"
            />
            <div
              v-if="logo.showSeparator"
              class="hidden h-16 w-0.5 border-l border-gray-200 sm:block"
            />
          </template>
        </div>
        <div class="mt-auto hidden flex-row sm:block">
          <legal-documents-link
            :legal-documents="legalDocuments"
            :copyright-text="copyrightText"
            :copyright-year="copyrightYear"
            :text-color-class="textColorClass"
            class="flex flex-row gap-x-6 text-xs"
            data-testid="legal-documents-link"
          />
        </div>
        <div class="mr-0 mt-auto w-max flex-col items-end justify-end sm:hidden">
          <div class="flex flex-row gap-3">
            <mok-button
              v-if="secondFooter.sponsorHomeLink"
              :href="secondFooter.sponsorHomeLink"
              :color="iconColorClass"
              :icon="PhHouse"
              target="_blank"
            />
            <mok-button
              v-if="secondFooter.contactEmail"
              :href="`mailto:${secondFooter.contactEmail}`"
              :color="iconColorClass"
              :icon="PhEnvelope"
              target="_blank"
            />
            <mok-button
              v-if="secondFooter.facebookLink"
              :href="secondFooter.facebookLink"
              :color="iconColorClass"
              :icon="FacebookIcon"
              target="_blank"
            />
            <mok-button
              v-if="secondFooter.instagramLink"
              :href="secondFooter.instagramLink"
              :color="iconColorClass"
              :icon="PhInstagramLogo"
              target="_blank"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-row sm:hidden"
      >
        <div class="w-full">
          <legal-documents-link
            :legal-documents="legalDocuments"
            :copyright-text="copyrightText"
            :copyright-year="copyrightYear"
            :text-color-class="textColorClass"
            class="flex flex-col text-xs"
            data-testid="legal-documents-link"
          />
        </div>
        <div
          class="flex w-full justify-end self-end text-base"
        >
          <div class="flex flex-col">
            <div
              v-if="secondFooter.contactPhone"
              class="flex flex-col py-0"
            >
              <div class="flex flex-row justify-start sm:items-center">
                <PhPhone
                  weight="fill"
                  class="mr-1 flex size-4 first-letter:fill-current sm:mr-4 sm:size-8 sm:items-center"
                  :class="[textColorClass]"
                />
                <div class="flex flex-col items-center text-xs font-medium sm:font-bold">
                  <p>
                    {{ secondFooter.contactPhone }}
                  </p>
                </div>
              </div>
            </div>
            <base-image
              v-if="secondFooter.regulatoryComplianceImageUrl"
              class="mt-4 h-5 max-w-sm justify-end self-end overflow-hidden object-contain"
              :src="secondFooter.regulatoryComplianceImageUrl"
              :webp-src="secondFooter.regulatoryComplianceWebpImageUrl"
              :blurhash="secondFooter.regulatoryComplianceImageBlurhash"
            />
          </div>
        </div>
      </div>
      <div class="hidden flex-col items-start sm:flex">
        <p class="shrink-0 flex-row text-xs">
          {{ $t('sections.footers.secondFooter.contactPhoneText') }}
          <span class="font-semibold">{{ secondFooter.contactPhone }}</span>
        </p>
        <div class="mt-1.5 flex flex-row gap-4">
          <mok-button
            v-if="secondFooter.sponsorHomeLink"
            :href="secondFooter.sponsorHomeLink"
            :color="iconColorClass"
            :icon="PhHouse"
            size="medium"
            target="_blank"
          />
          <mok-button
            v-if="secondFooter.contactEmail"
            :href="`mailto:${secondFooter.contactEmail}`"
            :color="iconColorClass"
            :icon="PhEnvelope"
            target="_blank"
          />
          <mok-button
            v-if="secondFooter.facebookLink"
            :href="secondFooter.facebookLink"
            :color="iconColorClass"
            :icon="FacebookIcon"
            target="_blank"
          />
          <mok-button
            v-if="secondFooter.instagramLink"
            :href="secondFooter.instagramLink"
            :color="iconColorClass"
            :icon="PhInstagramLogo"
            target="_blank"
          />
        </div>
        <base-image
          v-if="secondFooter.regulatoryComplianceImageUrl"
          class="mt-4 h-14 max-w-sm justify-end self-end overflow-hidden object-contain"
          :src="secondFooter.regulatoryComplianceImageUrl"
          :webp-src="secondFooter.regulatoryComplianceWebpImageUrl"
          :blurhash="secondFooter.regulatoryComplianceImageBlurhash"
        />
      </div>
    </div>
  </div>
</template>
