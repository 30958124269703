<script setup lang="ts">
import { computed, type Component } from 'vue';

type Color = 'primary' | 'error' | 'success' | 'warning';
type Shape = 'circle' | 'rounded';

export type Props = {
  variant?: 'primary' | 'secondary';
  icon: Component;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  color?: Color;
  shape?: Shape;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'primary',
  color: 'primary',
  shape: 'circle',
});

/* Force tailwind to include the possible classes in bundle */
// @ts-expect-error unused variable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colorClasses = {
  primary: 'fill-primary-700 fill-primary-600 bg-primary-100 border-primary-200',
  error: 'fill-error-700 fill-error-600 bg-error-100 border-error-200',
  success: 'fill-success-700 fill-success-600 bg-success-100 border-success-200',
  warning: 'fill-warning-700 fill-warning-600 bg-warning-100 border-warning-200',
};

const iconContainerSizeStyles = {
  xs: 'h-4',
  sm: 'h-6',
  md: 'h-8',
  lg: 'h-10',
  xl: 'h-14',
  '2xl': 'h-16',
};

const iconSizes = {
  xs: '8px',
  sm: '12px',
  md: '16px',
  lg: '20px',
  xl: '24px',
  '2xl': '32px',
};

const iconContainerSizeStyle = computed(() => iconContainerSizeStyles[props.size]);
const iconSize = computed(() => iconSizes[props.size]);

const iconIntensities = {
  primary: 700,
  secondary: 600,
};

const iconColorStyle = computed(() => (
  `fill-${props.color}-${iconIntensities[props.variant]}`
));

const iconContainerVariantStyle = computed(() => (
  {
    primary: `bg-${props.color}-100`,
    secondary: `bg-white border border-${props.color}-200`,
  }[props.variant]
));

const roundedBorderRadius = {
  xs: 'rounded-sm',
  sm: 'rounded',
  md: 'rounded-lg',
  lg: 'rounded-lg',
  xl: 'rounded-lg',
  '2xl': 'rounded-lg',
};

const iconContainerShapeStyle = computed(() => ({
  circle: 'rounded-full',
  rounded: roundedBorderRadius[props.size],
}[props.shape]));

</script>

<template>
  <div
    class="flex aspect-square items-center justify-center"
    :class="[iconContainerSizeStyle, iconContainerVariantStyle, iconContainerShapeStyle]"
  >
    <component
      :is="icon"
      :class="iconColorStyle"
      :size="iconSize"
    />
  </div>
</template>
