<script setup lang="ts">
import { MokButton } from '@mok-labs/components';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed, ref } from 'vue';

import type { UserServiceHistoryItem } from '@/types/user-services-history-item';

const props = defineProps<{
  currentStatus: string;
  statusHistory: UserServiceHistoryItem['statusHistory'];
}>();

const { greaterOrEqual } = useBreakpoints(breakpointsTailwind);
const mdOrLarger = greaterOrEqual('md');

const showDetails = ref(false);
const responsiveShowDetails = computed(() => mdOrLarger.value || showDetails.value);

type Step = { id: string; status: string; date?: Date };

const possibleSteps = [
  'pending',
  'assigned',
  'started',
  'finished',
  'cancelled',
];

const realPossibleSteps = computed(() => {
  if (props.currentStatus === 'cancelled') {
    return possibleSteps
      .filter((key) => key === 'cancelled' || Object.keys(props.statusHistory).includes(key));
  }

  return possibleSteps.filter((key) => key !== 'cancelled');
});

const currentStatusIndex = computed(() => {
  const index = possibleSteps.indexOf(props.currentStatus);
  if (props.currentStatus === 'finished') {
    return index + 1;
  }

  return index;
});

const statusHistoryWithDates = computed(() => Object.keys(props.statusHistory)
  .filter((key) => possibleSteps.includes(key))
  .reduce((acc, key) => {
    acc[key] = new Date(props.statusHistory[key]);

    return acc;
  }, {} as Record<string, Date>));

function getStepStatus(index: number) {
  if (index < currentStatusIndex.value || props.currentStatus === 'cancelled' || props.currentStatus === 'finished') {
    return 'finished';
  } else if (index === currentStatusIndex.value) {
    return 'inProgress';
  }

  return 'pending';
}

const steps = computed(() => {
  const formattedSteps: Step[] = realPossibleSteps.value.map((step, index) => ({
    id: step,
    status: getStepStatus(index),
    date: statusHistoryWithDates.value[step] || undefined,
  }));

  return formattedSteps;
});
</script>

<template>
  <div class="flex flex-col">
    <div class="mb-6 flex">
      <base-mok-stepper
        class="mr-6"
        :steps="steps"
        :current-step="currentStatusIndex"
        direction="vertical"
      />
      <ul class="flex flex-col justify-between">
        <li
          v-for="step in steps"
          :key="step.id"
          class="my-4 flex flex-col gap-1"
        >
          <span
            v-if="step.status !== 'finished'"
            class="text-xs"
            :class="step.status === 'inProgress' ? ' font-semibold text-primary-600' : 'text-gray-400'"
          >
            {{ $t(`serviceHistory.show.stepStatuses.${step.status}`) }}
          </span>
          <span
            v-else-if="step.date"
            class="text-xs text-gray-500"
          >
            {{ $d(step.date as Date, 'long') }}
          </span>
          <p class="text-base font-semibold text-gray-800">
            {{ $t(`serviceHistory.statuses.${step.id}.name`) }}
          </p>
          <p
            v-if="responsiveShowDetails"
            class="overflow-hidden text-xs text-gray-500"
          >
            {{ $t(`serviceHistory.statuses.${step.id}.description`) }}
          </p>
        </li>
      </ul>
    </div>
    <mok-button
      v-if="!mdOrLarger"
      :label="showDetails ? $t('serviceHistory.show.hideDetails') : $t('serviceHistory.show.showDetails')"
      theme="link"
      icon-position="right"
      @click="showDetails = !showDetails"
    />
  </div>
</template>
